const config = {
  breakpoints: {
    DESKTOP: 1024,
    TABLET: 768,
    MOBILE: 576,
  },
  colors: {
    primary: 'black',
    secondary: '#3035c5',
    alt: '#101325',
    background: '#F4F4F4',
    text: '#242424',
  },
  routes: {
    home: '/',
    about: '/about',
    contact: '/contact',
    projects: '/projects',
  },
  zIndex: {
    header: 1,
  },
  sizes: {
    header: 90,
    spacing: 24, // used for padding / margins
  },
};

export default config;
