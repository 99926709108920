import { css } from 'styled-components';
import config from '../config';
import { Media } from '../helpers';

export const typography = {
  h1: css`
    font-family: 'Toronto Subway Bold', sans-serif, -apple-system,
      BlinkMacSystemFont, Helvetica, Arial, 'Apple Color Emoji';
    font-size: 6em;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: ${config.colors.text};

    ${Media.MOBILE`
      font-size: 5em;
    `};
  `,
  h2: css`
    font-family: 'Toronto Subway Bold', sans-serif, -apple-system,
      BlinkMacSystemFont, Helvetica, Arial, 'Apple Color Emoji';
    font-size: 4em;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: ${config.colors.text};
  `,
  h3: css`
    font-family: 'Toronto Subway Bold', sans-serif, -apple-system,
      BlinkMacSystemFont, Helvetica, Arial, 'Apple Color Emoji';
    font-size: 3em;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: ${config.colors.text};
  `,
  h4: css`
    font-family: 'Toronto Subway Bold', sans-serif, -apple-system,
      BlinkMacSystemFont, Helvetica, Arial, 'Apple Color Emoji';
    font-size: 2em;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: ${config.colors.text};
  `,
  h6: css`
    font-family: 'Toronto Subway Bold', sans-serif, -apple-system,
      BlinkMacSystemFont, Helvetica, Arial, 'Apple Color Emoji';
    font-size: 1.5em;
    margin: 0;
    letter-spacing: 2px;
    color: ${config.colors.text};
  `,
  p: css`
    line-height: 26px;
    font-weight: 300;
    font-size: 1em;
    color: ${config.colors.text};
  `,
  span: css`
    line-height: 20px;
    font-weight: 300;
    font-size: 0.8em;
    color: ${config.colors.alt};
  `,
};
