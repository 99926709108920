import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import '../../styles/fonts.css';

import { GlobalStyles } from '../../styles/Global';

import Header from '../Header';
import config from '../../config';
import NavigationDrawer from '../NavigationDrawer';

// Nunito for all fonts, quicksand for old headings font, TTC font nmew

const Content = styled(motion.div)`
  height: calc(100vh - 90px);
  min-height: 100%;
  width: 100vw;
  background-color: ${config.colors.background};
`;

function AnimatedTransition({ children, path }) {
  return (
    <AnimatePresence mode="wait">
      <Content
        key={path}
        initial={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
      >
        {children}
      </Content>
    </AnimatePresence>
  );
}

function PageLayout({ children, location }) {
  const [drawerOpen, toggleDrawer] = React.useState(false);

  const PageChilrenInjected = React.Children.map(children, (child, index) => {
    if (index === 0 && React.isValidElement(child)) {
      // Inject prop into the first child
      return React.cloneElement(child, { toggleDrawer });
    }
    return child;
  });

  return (
    <>
      <GlobalStyles />
      <Header currentPath={location.pathname} toggleDrawer={toggleDrawer} />
      <NavigationDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
      <AnimatedTransition path={location.pathname}>
        {PageChilrenInjected}
      </AnimatedTransition>
    </>
  );
}

export default PageLayout;
