import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import config from '../../config';

import { Media } from '../../helpers';

const applyPadding = (apply, vertical) => {
  if (apply && vertical) {
    return css`
      ${Media.MOBILE`
        padding: ${config.sizes.spacing}px;
      `};
      padding: ${config.sizes.spacing * 2}px;
    `;
  }

  if (apply && !vertical) {
    return css`
      ${Media.MOBILE`
          padding: 0 ${config.sizes.spacing}px;
      `};
      padding: 0 ${config.sizes.spacing * 2}px;
    `;
  }

  return css`
    padding: 0;
  `;
};

export const ContainerContent = styled.div`
  ${props => applyPadding(props.padding, props.verticalPadding)};
  max-width: ${config.breakpoints.DESKTOP}px;
  height: auto;
  margin: 0 auto;
`;

const Container = ({ children, padding, verticalPadding }) => (
  <ContainerContent padding={padding} verticalPadding={verticalPadding}>
    {children}
  </ContainerContent>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.bool,
  verticalPadding: PropTypes.bool,
};

Container.defaultProps = {
  padding: true,
  verticalPadding: true,
};

export default Container;
