import React from 'react';
import styled from 'styled-components';

import Container from '../Container';
import config from '../../config';
import Icon from '../Icon';

import { typography } from '../../styles/typography';
import { Link } from 'gatsby';

const Content = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  height: ${config.sizes.header}px;
  width: 100%;
  z-index: ${config.zIndex.header};
  background-color: ${config.colors.background};

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledNameLink = styled(Link)`
  ${typography.h4};
  text-decoration: none;
`;

const IconButton = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
`;

const Header = ({ currentPath, toggleDrawer }) => {
  const [hideNameText, toggleHideNameText] = React.useState(true);

  React.useEffect(() => {
    if (currentPath === '/') {
      toggleHideNameText(true);
    } else {
      toggleHideNameText(false);
    }
  }, [currentPath]);

  return (
    <Content>
      <Container verticalPadding={false}>
        <IconButton onClick={() => toggleDrawer(true)}>
          <Icon name="menu" size={30} />
        </IconButton>

        {!hideNameText && <StyledNameLink to="/">drnt</StyledNameLink>}
      </Container>
    </Content>
  );
};

export default Header;
