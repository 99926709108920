import React from 'react';

const useHasMounted = () => {
  const [didMount, setDidMount] = React.useState(false);

  React.useEffect(() => {
    setDidMount(true);
  }, []);

  return didMount;
};

export default useHasMounted;
