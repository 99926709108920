import React from 'react';
import styled from 'styled-components';

import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import config from '../../config';
import { typography } from '../../styles/typography';

export const Wrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: transparent;
  pointer-events: none;
`;

// subract one from the z-index so it shows below the drawer
export const BackgroundOverlay = styled(motion.div)`
  z-index: 998;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  pointer-events: all;
`;

// calc(100% - 80px) because the header is 80px, we have to account for it
// we do +1 on the top so we can see the bottom border
export const Body = styled(motion.div)`
  pointer-events: all;
  z-index: 999;
  position: relative;
  left: 0;
  height: 100%;
  width: 85vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${config.colors.primary};
  padding: 0 ${config.sizes.spacing}px;
`;

const ListContainer = styled.ul`
  height: auto;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    margin: ${config.sizes.spacing * 2}px 0;
    a {
      display: block;
      ${typography.h4};
      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
function NavigationDrawer({ open, toggleDrawer }) {
  return (
    <AnimatePresence>
      {open && (
        <Wrapper
          key={`drawer-wrapper-${open}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <BackgroundOverlay
            key="bg-overlay"
            onClick={() => toggleDrawer(false)}
          />
          <Body
            key="drawer-body"
            initial={{
              x: '-100%',
            }}
            animate={{
              x: 0,
            }}
            exit={{
              x: '-100%',
            }}
            transition={{ type: 'tween' }}
          >
            <ListContainer>
              <li>
                <Link to="/" onClick={() => toggleDrawer(false)}>
                  <StaticImage
                    src="../../assets/images/logowhite.png"
                    alt="drnt logo"
                    width={50}
                    height={50}
                  />
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={() => toggleDrawer(false)}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/projects" onClick={() => toggleDrawer(false)}>
                  Projects
                </Link>
              </li>
              <li>
                <Link to="/articles" onClick={() => toggleDrawer(false)}>
                  Articles
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={() => toggleDrawer(false)}>
                  Contact
                </Link>
              </li>
            </ListContainer>
          </Body>
        </Wrapper>
      )}
    </AnimatePresence>
  );
}

export default NavigationDrawer;
