exports.components = {
  "component---src-components-article-layout-index-jsx-content-file-path-opt-build-repo-src-articles-2022-mac-slippi-streaming-index-mdx": () => import("./../../../src/components/ArticleLayout/index.jsx?__contentFilePath=/opt/build/repo/src/articles/2022/mac-slippi-streaming/index.mdx" /* webpackChunkName: "component---src-components-article-layout-index-jsx-content-file-path-opt-build-repo-src-articles-2022-mac-slippi-streaming-index-mdx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */)
}

