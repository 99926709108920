import React from 'react';
import { FaGithub } from '@react-icons/all-files/fa/FaGithub';
import { MdOpenInBrowser } from '@react-icons/all-files/md/MdOpenInBrowser';
import { FiMenu } from '@react-icons/all-files/fi/FiMenu';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';

import config from '../../config';

const IconTable = {
  arrowRight: <FaArrowRight />,
  github: <FaGithub />,
  browserLink: <MdOpenInBrowser />,
  menu: <FiMenu />,
};

const useHover = () => {
  const [hovering, setHovering] = React.useState(false);

  const returnValues = {
    onMouseOver: () => setHovering(true),
    onMouseOut: () => setHovering(false),
    onTouchEnd: () => setHovering(false),
    onTouchStart: () => setHovering(true),
  };

  return [hovering, returnValues];
};

const Icon = ({ name = '', size = 25, color = 'black', children }) => {
  const IconForName = IconTable[name];

  const [hovering, hoverProps] = useHover();

  return IconForName
    ? React.cloneElement(
        IconForName,
        {
          color: hovering ? config.colors.primary : color,
          size,
          ...hoverProps,
        },
        children
      )
    : null;
};

export default Icon;
